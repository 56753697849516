<template>
    <div>

    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
    beforeRouteEnter (to, from, next) {
        console.log(to);
        next(vm => {
            vm.$router.push({name:from.name, query:to.query})
        })
    }
}
</script>